<template>
	<div class="tcp-main">
		<el-card>
			<div class='tcp-top'>
				<span class="hcp-title">火车票改签</span>	
				<refund-change-rule :orderType="11011"></refund-change-rule>
			</div>
			<div class="tcp-con">
				<div class="tcp-con-f" v-for="(item, index) in allData.changePsgList" :key="item.empId">
					<!-- <el-checkbox v-model="item.checked"  class="checkBox" @change="getTicket(item, index)"></el-checkbox> -->
					<el-checkbox v-model="item.checked" :disabled="item.isChange!=0 || item.isReturn!=0" class="checkBox" @change="getTicket(item, index)"></el-checkbox>
					<span class="tcp-fl1">{{item.passengerName}} (身份证{{item.idCode}})</span>
					<span class="tcp-fl2">座席：{{allData.seatTypeName}}</span>
					<span class="tcp-fl3">座位号：{{item.seatCode}}</span>
					<span class="tcp-fl4">票价：¥{{item.ticketPrice}}</span>
				</div>
				<div class="tcp-con-t">
					<div class="tcp-l1">{{allData.trainCode}}</div>
					<div class="tcp-l2" >
						<div class='tcp-l2-t'>
							<div class="tcp-t-text">{{allData.fromTime}}</div>
							<div class="time-text"></div>
							<div class="tcp-t-text">{{allData.toTime}}</div> 
						</div>
						<div class='tcp-l2-f'>
							<div class="tcp-f-text">{{allData.fromStationName}}</div> 
							<div class="sethx"></div>
							<div class="tcp-f-text">{{allData.toStationName}}</div>
						</div> 
					</div>
					<div class="tcp-l3">{{allData.fromDate}} {{allData.fromWeek}}</div>
				</div>
				
				<div v-if="changeTrainInfo.trainCode" class="chooseTr">
					<div class="choose-main">
						<div class="choose-l1 ">{{changeTrainInfo.trainCode}} ({{changeTrainInfo.selectSeat.seatName}})</div>
						<div class="choose-l2"> 
							<div  class="lt">
								<div class="lt-t-text">{{changeTrainInfo.startTime}}</div>
								<div class="time-text">{{changeTrainInfo.runTime}}</div>
								<div class="lt-t-text">{{changeTrainInfo.arriveTime}}</div> 
							</div>
							<div class="lf">
								<div class="lf-f-text">{{changeTrainInfo.fromStationName}}</div> 
								<div class="lf-icon" ></div>
								<div class="lf-f-text">{{changeTrainInfo.toStationName}}</div>
							</div> 
						</div>
						<div class="choose-l3">{{changeTrainInfo.trainStartDate}}</div>
						<div class="choose-l4 el-icon-close " @click="clearTrainInfo"></div>
					</div>
				</div>
				<div class="changeDate">
					<el-date-picker
						type="date"
						v-model="dateValue"
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptions(bookDateRange.minDay,bookDateRange.maxDay)"
						class="inlineB marR20 box"
						style="width: 235px;"
						@change="changeDate"
						placeholder="选择日期">  
					</el-date-picker>
					<el-button type="primary" class="btns" @click="queryTrain">查询车次</el-button>
				</div>
			</div>
		</el-card>
		<el-card class="mgt20" v-if="needApprove && allData.tripType==1">
			<!-- 审批规则 -->
			<div class="setSelect approval-rule" >
				<h1 slot="header" class="text-left approval-rule-title">审批规则</h1>
				<el-select
					v-model="chooseSpgz.gzmc" 
					:loading="loadingSpgzList"
					value-key
					automatic-dropdown
					@focus="getSpgzList()"
					@change="getCurrentSpgz" 
					:placeholder="!isWuXuShenPi?'请选择审批规则':'无需审批'"	
					class="bfb-w100"
					>
				    <el-option
				      v-for="item in spgzList"
				      :key="item.gzId"
				      :label="item.gzmc"
				      :value="item">
				    </el-option>
				</el-select> 
			</div>
		</el-card>
		<!-- 在线选座 -->
		<div class="el-card2 mgt20">			
			<train-select-seat
				:optionSeatNumber="optionSeat"
				:cxrLength="cxrList.length"
				:seatInfo="seatInfo"
				@chooseSeatList="chooseSeatList"
				v-if="(changeSeatType == 'O' || changeSeatType == 'M' || changeSeatType == 9) && (optionSeat<6 && optionSeat>0)"
			>
			</train-select-seat>
		</div>
		 
		<div class="dialog" v-if="queryVisible">
			<div class="dialog-main">
				<div class="dialogTitle">
					<span class="font18">火车票查询</span>
					<span class="el-icon-close iconClose" @click="closeDialog"></span>
				</div>
				<train-search
						:disabled="true"
						:orderChange="true"
						:bookDateRange="bookDateRange"
						@chooseDate="chooseSearchDate"
						@trainListlength="trainListlength"		  
						></train-search>
				<div class="search-content">
					<train-list-loading :loading="trQueryStatus==1"></train-list-loading>
					<train-query-list v-if="trQueryStatus!=1 && trListlength>0" :trCityQuery="trCityQuery" @chooseDate="chooseDate" :isOrderChange="true" @close="chooseChangeTrainInfoValid"></train-query-list>
				</div>			
			</div>	
		</div>
	</div>
</template>

<script>
	import mixin from '@/mixin';
import TrainSelectSeat from "@/views/tr/trComponents/trEdit/trainYdEdit/TrainSelectSeat";
import TrainListLoading from '@/views/tr/trComponents/trQuery/TrainListLoading';
import TrainQueryList from '@/views/tr/trComponents/trQuery/TrainQueryList';
import TrainSearch from '@/views/tr/trComponents/trQuery/TrainSearch';
import { mapGetters, mapMutations, mapState } from 'vuex';
import RefundChangeRule from './RefundChangeRule';
	export default{
		name: "TrainChangePerson",
		mixins:[mixin.ccsqdMixin],
		data() {
			return {
				chooseList: [],
				dateValue:'',
				changeTrainInfo:{},// 改签的火车车次信息
				queryVisible: false, // 查询改签单
				trListLoading: 0, // 加载
				trListlength: 0, //火车票列表长度
				needApprove: false,
				loadingSpgzList: false,
				spgzList:[], // 审批规则列表
				// 选中的审批规则
				chooseSpgz:{
					gzId:'', // 审批规则id
					gzmc:'', // 审批规则名称
					gzdm:''  // 审批规则代码
				},
				spkzMap:{},
				empIdList: [],
				price:0, // 改签火车票的订单金额
				trCityQuery:{},
				isWuXuShenPi:true, // 是否无需审批
				wb: 0,//改签单选择车票是否违背
				chunyunObj: {}, // 假日数据 
				seatInfo: {
					seatIdList: [],
					chooseSeatList: [],
				}, // 坐席信息
			}
		},
		props:{
			allData:{
				type: Object,
				default() {
				    return {
						empInfo:{},
					}
				}	
			}
		},
		created(){
			let userInfo = this.$common.sessionget('user_info') || {};
			this.empInfo = userInfo.empInfo || {};
			this.getBClass();
			
			// 获取假日数据
			this.getChunyunObj();
		},
		components:{
			'train-query-list': TrainQueryList,
			'refund-change-rule':RefundChangeRule,
			'train-search':TrainSearch,
			'train-list-loading':TrainListLoading,
			TrainSelectSeat,
		},
		computed:{
			...mapState({
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
				
			}),
			...mapGetters({
				getVipParamterByParNo: 'common/getVipParamterByParNo',
			}),
			// 
			//可选座位数量
			optionSeat() {
				return this.empIdList.length;
			},
			// 选中的出行人
			cxrList() {
				return this.allData.changePsgList || [];
			},
			changeSeatType() {
				let changeTrainInfo = this.changeTrainInfo || {},
					selectSeat = changeTrainInfo.selectSeat || {},
					seatType = selectSeat.seatType || '';
					
				return	seatType || '';
			},
			// 日期范围
			bookDateRange(){
				// 方法来自于mixins
				let dateRangeObj = {};
				dateRangeObj = this.getBookDateRange(10904,2,(this.searchData.maxDay-1));
				return dateRangeObj
			},
			//火车票查询状态
			trQueryStatus(){
				return this.searchData.trQueryStatus;
			}
			
		},
		
		watch:{
			allData:{
				handler(val){
					if(val.tripType == 1){
						this.getNeedApprove();
					}
					
					if(val.changePsgList && val.changePsgList.length == 1){
						this.getTicket();
					}
				},
				immediate: true
			},
			// 修改坐席类型
			changeSeatType() {
				this.chooseSeatList();
			},
			// 修改选中人数
			optionSeat() {
				this.chooseSeatList();
			},
		},
		methods:{
			...mapMutations({
				SET_SearchData: 'train/setSearchData',
				SET_TrainEdit: 'train/setTrainEdit',
			}),
			// 获取假日数据
			getChunyunObj() {
			  var getParameter = this.$conn.getConn('commu.getBParam');
			  getParameter({
			    paramNo: 16013
			  }, (res) => {
			    this.chunyunObj = res.data || {};
			  });
			},
			// 日期控制 最小天数 ，最大天数 ， 因公因私， 因私最大天数
			pickerOptions(minDay,maxDay){
				let minTime = this.$common.getStartDateTimeStamp(minDay);
				let maxTime = this.$common.getStartDateTimeStamp(maxDay);
				let cityInfo = this.allData; //订单信息
				let time = cityInfo.fromDate+' '+cityInfo.fromTime;
				let lastTimestamp = this.$common.getTimeStamp(time); //火车票出发 时间戳
				// let timestamp = cityInfo.timestamp; //调用接口 时间戳 
				// let changeTime = timestamp > (lastTimestamp-0)  // 已发车 true 
				let today = this.$common.getToDay() + ' 00:00';
				let todaytamp = this.$common.getTimeStamp(today);
				return {
					//定义不可选择时间 暂无预约功能 查找有效日期
					// 查询出差申请单预订行程日期可否变更 0不可更改  1 按行程更改 2 出差申请单日期范围更改
					disabledDate(time) {
						// 火车票过了发车时间就别想改签了
						// if(changeTime){
						// 	return time.getTime() > lastTimestamp || time.getTime() < timestamp;
						// }else{
							// 当天之后 并且得在预售范围内
							return (time.getTime() < minTime  || time.getTime() > maxTime) && (lastTimestamp >= todaytamp);
						// }
					},
				}
			},
			// 改签票是否需要审批
			getNeedApprove(){
				var query = this.$conn.getConn('commu.getUserTravelInfo');
				query({},res=>{
					var data = res.data||{};
					var approvalBean = data.approvalBean||{};
					/*
						appBook : 审批属性（预订人）0审批 1全免 ,
						appTravel : 出差审批属性 0审批 1全免 2个免 ,
						spkzMap : 审批控制map格式<单据类型,审批控制对象> ,
						vipSpgzBean: 审批规则对象
						
						1 appBook:1 全免 appTravel:1  全免
						2 看 spkzMap是否有返回值，没有就是免审， 有参数按规则匹配是否需要审批  
						3 needApprove 需要审批才匹配审批规则
						
					*/
					if(approvalBean.appTravel==1 && approvalBean.appBook==1){
						this.needApprove = false;
					}else{
						if(approvalBean.spkzMap && JSON.stringify(approvalBean.spkzMap)!="{}"){
							this.spkzMap = approvalBean.spkzMap;
							this.needApprove = this.spkzMap['11011'].sfkq==1 || (this.spkzMap['11011'].sfkq==2 && this.wb)?true:false;
						}else{
							this.needApprove = false;
						}
						
					}
					
					if(this.needApprove){
						this.getSpgzList()
					}
					
				},err=>{
					
				})
			},
			// 改签单获取火车票预定最大天数
			getBClass(){
				this.$conn.getConn('commu.getBClass')({id: 14601},(res)=>{
					let data = res.data || {};
					let list = data.list || [];
					if(list.length>0){
						// 最大预定天数
						this.maxDay = list[0].by1;
						this.SET_SearchData({
							maxDay: this.maxDay,
						})
					}
				},(err)=>{
					
				})
			},
			// 审批规则模块
			getSpgzList(){
				let isWei = false;
				if(this.wb){
					isWei = true;
				}
				// 改签的员工id
				this.loadingSpgzList = true;
				if(this.allData.ccsqdId && this.allData.tripType == 1){
					this.getTravelApprovalRules(isWei,this.empIdList);
				}else{
					this.getSingleApprovalRules(isWei,this.empIdList);
				}
				// this.getSingleApprovalRules(isWei,this.empIdList);
				
			},
			// 出差申请单模式下匹配审批规则
			getTravelApprovalRules(isWei,psgArr){
				let objParams = {};
				objParams = {
				  "bookEmpId": this.empInfo.empId,
				  "corpId":  this.empInfo.corpId,
				  "fcny":  this.price,
				  "ifViolate": isWei?1:0,
				  "orderType": 11011, // 单据类型
				  "projectId": '',
				  "routeId": this.allData.routeId,
				  "travelEmpIds": psgArr.join(','),
				}  
				this.$conn.getConn('commu.travelRouteMatchSpgz')(objParams,(res)=>{
          this.spgzList = res.data || [];
          this.loadingSpgzList = false;
          if(this.spgzList.length==0){
          	this.isWuXuShenPi = true;
          	this.chooseSpgz = {
          		gzId:'',
          		gzmc:'',
          		gzdm:'',
          	}
          }else{
          	if(this.spgzList.length==1){
          		this.chooseSpgz.gzId = this.spgzList[0].gzid; // 审批规则id
          		this.chooseSpgz.gzmc = this.spgzList[0].gzmc; // 审批规则名称
          		this.chooseSpgz.gzdm = this.spgzList[0].gzdm; // 审批规则代码
          		this.$emit('chooseSpgz',this.chooseSpgz)
          	}
          	this.isWuXuShenPi = false;
          }
        },(err)=>{
          this.spgzList = [];
          this.isWuXuShenPi = false;
          this.loadingSpgzList = false;
          this.$alert(err.tips,'温馨提示',{
          	type: 'error' 
          })
        })
			},
			// 单订模式下批配审批规则
			getSingleApprovalRules(isWei,psgArr){
				let objParams = {};
				objParams = {
				  "bookEmpId": this.empInfo.empId,
				  "corpId":  this.empInfo.corpId,
				  "ifViolate": isWei?1:0,
				  "orderType": 11011, // 单据类型
				  "projectId": '',
				  "travelEmpIds":  psgArr.join(','),
				}
				this.$conn.getConn('commu.singleModelMatchSpgz')(objParams,(res)=>{
          this.spgzList = res.data || [];
          this.loadingSpgzList = false;
          if(this.spgzList.length==0){
          	this.isWuXuShenPi = true;
          	this.chooseSpgz = {
          		gzId:'',
          		gzmc:'',
          		gzdm:'',
          	}
          }else{
          	if(this.spgzList.length==1){
          		this.chooseSpgz.gzId = this.spgzList[0].gzid; // 审批规则id
          		this.chooseSpgz.gzmc = this.spgzList[0].gzmc; // 审批规则名称
          		this.chooseSpgz.gzdm = this.spgzList[0].gzdm; // 审批规则代码
          		this.$emit('chooseSpgz',this.chooseSpgz)
          	}
          	this.isWuXuShenPi = false;
          }
        },(err)=>{
          this.spgzList = [];
          this.isWuXuShenPi = false;
          this.loadingSpgzList = false;
          this.$alert(err.tips,'温馨提示',{
          	type: 'error' 
          })
        })
			},
			// 获取当前的审批规则
			getCurrentSpgz(spgzItem){
				this.chooseSpgz.gzId = spgzItem.gzid; // 审批规则id
				this.chooseSpgz.gzmc = spgzItem.gzmc; // 审批规则名称
				this.chooseSpgz.gzdm = spgzItem.gzdm; // 审批规则代码
				this.$emit('chooseSpgz',this.chooseSpgz)
			},
			//选中改签日期
			changeDate(date){
				this.chooseTrainDate = date;
				this.SET_SearchData({
					depDate: this.chooseTrainDate,
				})
			},
			//列表日期选中日期
			chooseSearchDate(date){
				this.dateValue = date;
			},
			// 选中可改签的乘车人
			getTicket(){
				this.chooseList = [];
				this.empIdList = [];
				this.allData.changePsgList.forEach((item,index)=>{
					if(item.checked==true){
						this.chooseList.push(item.psgId)
						this.empIdList.push(item.empId)
					}
				})
				if(this.needApprove){
					this.getSpgzList();
				}
				this.$emit('chooseChangeList',this.allData)	
			},
			// 验证是否可查询火车车次
			getVaild(){
				let cityInfo = this.allData;
				// let time = cityInfo.fromDate+' '+'00:00';
				// let lastTimestamp = this.$common.getTimeStamp(time);
				// let timestamp = cityInfo.timestamp;
				// let changeTime = (lastTimestamp-0) > timestamp

				let now = this.$common.getDateMing(new Date(), 0);
				
				var obj = {
						bool:true,
						errMsg:"",
					}
				if(!this.chooseTrainDate){
					obj.errMsg= '请选择日期';
					obj.bool = false;
					return obj
				}else if(this.chooseList.length==0){
					obj.errMsg= '请选择乘车人';
					obj.bool = false;
					return obj
				}else if(cityInfo.fromDate < now){
					obj.errMsg= '您的发车时间已过,无法在线提交改签';
					obj.bool = false;
					return obj
				}
				return obj;
			},
			// 查询火车票
			queryTrain(){
				var vaild = this.getVaild()
				if(!vaild.bool){
					this.$alert(vaild.errMsg,'温馨提示',{
						type: 'error' 
					})
					return false;
				}
				let cityInfo = this.allData;
				this.SET_SearchData({
					depCity: cityInfo.fromStationName, //出发城市
					arrCity: cityInfo.toStationName, //到达城市
					depCitySzm: cityInfo.fromStationCode, //出发城市三字码
					arrCitySzm: cityInfo.toStationCode, //到达城市三字码
					depInputName: cityInfo.fromStationName+'('+cityInfo.fromStationCode+')',// 出发城市加三字码
					arrInputName: cityInfo.toStationName+'('+cityInfo.toStationCode+')',// 到达城市加三字码
					changeTripType: cityInfo.tripType, // 用户下单时 订单因公因私 改签单
				})
				this.trCityQuery = {
					depCity: cityInfo.fromStationName, //出发城市
					arrCity: cityInfo.toStationName, //到达城市
				}
				this.queryVisible = true;
				this.SET_SearchData({
					search: true,
				})
				// setTimeout(()=>{
					
				// },500)
			},
			// 火车票列表长度
			trainListlength(num){
				this.trListlength = num
			},
			
			// 加载动画效果
			isLoading(num){
				this.trListLoading = num;
			},
			// 清除选中的火车票信息
			clearTrainInfo(){
				this.changeTrainInfo = {};
				this.$emit('chooseTrainInfo',this.changeTrainInfo)
			},
			// 改签单选中的火车票日期
			chooseDate(date){
				this.chooseTrainDate = date;
				this.dateValue = date;
				this.SET_SearchData({
					depDate: this.chooseTrainDate,
					search: true,
				})
			},
			chooseChangeTrainInfoValid(bool,trainData,seat) {
				this.queryVisible = bool;
				
				if (this.chunyunObj.value1 == 1) {
					let dateArr = this.chunyunObj.value2.split(" - "),
						dateStart = dateArr[0],
						dateEnd = dateArr[1];
					
					// || this.allData.fromDate >= dateStart && this.allData.fromDate <= dateEnd	
					if (this.chooseTrainDate >= dateStart && this.chooseTrainDate <= dateEnd) {
						this.$alert(this.chunyunObj.parDesc,'提示',{
							type:'warning',
							close: () => {
								this.chooseChangeTrainInfo(bool,trainData,seat)
							}
						})
					} else {
						this.chooseChangeTrainInfo(bool,trainData,seat)
					}	
				} else {
					this.chooseChangeTrainInfo(bool,trainData,seat)
				}
			},
			// 选中改签火车票 并关闭弹框
			chooseChangeTrainInfo(bool,trainData,seat){
				// 要改签的火车票信息
				this.changeTrainInfo = trainData;
				if(trainData.trainStartDate.indexOf('-')==-1){
					let date = trainData.trainStartDate.substring(0,4)+'-'+trainData.trainStartDate.substring(4,6)+'-'+trainData.trainStartDate.substring(6,8);
					this.changeTrainInfo.trainStartDate = date;
				}else{
					this.changeTrainInfo.trainStartDate = trainData.trainStartDate;
				}
				
				this.price = seat.price;
				this.wb = seat.wb;

				if(this.allData.tripType == 1){
					this.getNeedApprove();
		
					this.getSpgzList();
				}
			
				this.$emit('chooseTrainInfo',this.changeTrainInfo)
			},
			//关闭对话框
			closeDialog(){
				this.queryVisible = false;
			},
			// 选择坐席
			chooseSeatList(idList=[], list=[]) {
			    this.seatInfo = {
			      seatIdList: idList,
			      chooseSeatList: list,
			    }
				
				this.$emit('seatInfo', this.seatInfo);
			},
		}
	}
</script>

<style lang="scss" scoped>
	// .el-card.is-always-shadow{
	//     box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.02);
	//     border-radius: 5px;
	// }
	.mgt20 {
		margin-top: 20px;
	}
	.tcp-main{
		font-family: '微软雅黑';
		color: #223344;
		.flex{
			display: flex;
		}
		 
		.between{
			justify-content: space-between;
		}
		.el-card /deep/ .el-card__body{
			padding: 0;
		}
		.el-card2 /deep/ .el-card__body{
			padding: 30px;
		}
		.tcp-top{
			display: flex;
			justify-content: space-between;
			height: 80px;
			text-align: left;
			background-color: #006BB9;
			padding: 0 30px;
			.hcp-title{
				font-size: 24px;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 80px;
			}
		}
	
		.tcp-con{
			padding: 30px;
			.tcp-con-t{
				display: flex;
				background-color: #F3F5F8 ;
				height: 80px;
				padding:20px 0; 
				box-sizing: border-box;
				margin-bottom: 15px;
				.tcp-l1{
					line-height: 40px;
					width: 175px;
					border-right: 1px solid #D1DBE7 ;
					font-weight: bold;
					font-size: 18px;
				}
				.tcp-l2{
					width:631px;
					padding: 0 38px;
					border-right: 1px solid #D1DBE7 ;
					.tcp-l2-t{
						display: flex;
						.tcp-t-text{
							font-weight: bold;
							font-size: 18px;
							width:200px;
						}
						.time-text{
							width: 154px;
							font-size: 12px;
							font-weight: 400;
							line-height: 18px;
						}
					}
					.tcp-l2-f{
						display: flex;
						.tcp-f-text{
							font-size: 14px;
							font-weight: 400;
							line-height: 15px;
							padding-top: 2px;
							width:200px;
						}
						.sethx{
							background-color: #D1DBE7;
							margin-top: 7px;
							width: 154px;
							height: 1px;
						}
					}
				}
				.tcp-l3{
					line-height: 40px;
					width: 332px;
				}
			}
		
			.tcp-con-f{
				display: flex;
				height: 45px;
				padding: 13px 0;
				background-color: #F3F5F8 ;
				margin-bottom: 15px;
				box-sizing: border-box;
				.checkBox{
					width: 45px;
					line-height: 19px;
					border-right: 1px solid #D1DBE7;
				}
				.tcp-fl1{
					width: 354px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.tcp-fl2{
					width: 209px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.tcp-fl3{
					width: 254px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.tcp-fl4{
					width: 274px;
					line-height: 19px;
				}
			}
			
			.chooseTr{
				margin-top: 20px;
				.choose-main{
					display: flex;
					background-color: #F3F5F8 ;
					border: 1px solid #7BBAFF;
					padding: 20px 0;
					margin-bottom: 15px;
					height: 80px;
					box-sizing: border-box;
					.choose-l1{
						width: 175px;
						border-right: 1px solid #D1DBE7 ;
						font-weight: bold;
						font-size: 18px;
						line-height: 40px;
					}
					.choose-l2{
						padding: 0 20px;
						width: 571px;
						border-right: 1px solid #D1DBE7 ;
						.lt{
							display: flex;
							.lt-t-text{
								font-weight: bold;
								font-size: 18px;
								line-height: 18px;
								width: 188px;
							}
							.time-text{
								width: 154px;	
							}
						}
						.lf{
							display: flex;
							.lf-f-text{
								font-size: 14px;
								line-height: 14px;
								width: 188px;
								padding-top: 6px;
							}
							.lf-icon{
								width:154px;
								background-color: #D1DBE7;
								height: 1px;
								margin-top: 12px;
							}
						}
					}
					.choose-l3{
						width: 279px;
						border-right: 1px solid #D1DBE7 ;
						line-height: 40px;
					}
					.choose-l4{
						width: 131px;
						color:#006BB9;
						cursor: pointer;
						border-right: 1px solid #D1DBE7 ;
						font-weight: bold;
						font-size: 20px;
						line-height: 40px;
					}
				}
			}
			
			.changeN{
				font-size: 18px;
				font-weight: normal;
				color: #006BB9;
				line-height: 18px;
				text-align: left;
				margin: 30px 0 20px 0;
			}
			.changeDate{
				width: 200px;
				display: flex;
				/deep/ .el-input__inner {
					padding-left: 30px;
					height: 45px;
					width: 200px;
					line-height: 45px;
					background: #F9FBFF;
					border: 2px solid #D9E1ED;
					border-radius: 1px;
				}
				.btns{
					display: inline-block;
					height: 45px;
					font-size: 15px;
					border-radius: 0;
					margin-left: 20px;
					padding:10px 40px;
				}
			}
		}
	
		.dialog{
			
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.dialog-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #EEF1F6;
				width: 996px;
				padding: 20px;
				.dialogTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
					.iconClose{
						font-size: 24px;
						cursor: pointer;
					}
					.iconClose:hover{
						color:#00A7FA;
					}
				}
		
				.search-content{
					width: 100%;
					height: 600px;
					margin-top: 20px;
					overflow-x: hidden;
					overflow-y: auto;
					.tr-search {
						display: flex;
						justify-content: flex-start;
						flex-wrap: wrap;
						padding: 20px 0 40px 0;
					
						.tls-city {
							display: flex;
							justify-content: space-between;
						}
					
						.search-item {
							margin-right: 15px;
							/deep/ .el-input__prefix{
								color: #006BB9;
								text-align: center;
								width:25px;
							}
							.setInput {
								/deep/ .el-input__inner {
									height: 45px;
									width: 200px;
									line-height: 45px;
									background: #F9FBFF;
									border: 2px solid #D9E1ED;
									border-radius: 1px;
									font-size: 15px;
									font-weight: 400;
									color: #424242;
								}
							}
					
							.tr-city-qh {
								transform: rotate(90deg);
								font-size: 24px;
								height: 24px;
								margin: 10px 8px;
								display: inline-block;
							}
					
							.setDate {
								width: 200px;
					
								/deep/ .el-input__inner {
									padding-left: 30px;
									height: 45px;
									width: 200px;
									line-height: 45px;
									background: #F9FBFF;
									border: 2px solid #D9E1ED;
									border-radius: 1px;
								}
							}
					
							.search-btn {
								border-radius: 0;
								height: 45px;
								width: 235px;
								font-size: 15px;
								font-weight: 600;
							}
						}
					
						.pointer {
							cursor: pointer;
						}
					
					
					}
				
					.skelet{
						
						width: 100%;
						height:auto;
						margin-top: 10px;
						background-color: rgba(0,100,255,0.1);
						border-radius: 4px;
						
						.flex{
							display: flex;
						}
						.between{
							justify-content: space-between;
						}
					}
				}
			
					
			}
		}
		
		.setSelect{
			/deep/ .el-input__inner{
				width: 675px;
				height: 45px;
				background: #F4F9FF;
				border: 3px solid #006BB9;
				border-radius: 1px;
			}
			.text-left{
				text-align: left;
			}
		}
		.approval-rule{
			margin-bottom: 30px;
			text-align: left;
			padding-left: 30px;
		}
		.approval-rule-title{
			margin: 30px 0 15px 0;
		}
	}
</style>